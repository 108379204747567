// LastEpisode.js

import React from 'react';
import './LastEpisode.css';
import download from '../../assets/download.png';
import external from '../../assets/external.png';
import Spin from '../Spin/Spin';

const NowPlaying = React.lazy(() => import('../NowPlaying/NowPlaying'));

function MostRecent() {

    /* const audioSrc = "http://35.7.34.15:5150/(0) Last Episode/LastEpisode.mp3" */
    const audioSrc = "https://djdoubletwo.duckdns.org:5150/(0) Last Episode/LastEpisode.mp3"
    const driveLink = "https://drive.google.com/uc?export=download&id=1CJozT9E6QhB6tx_ZcnEyGIuS8hNwix_P"
    const epName = "1/17/25 The Dungeon Sound"
    const spinLink = "https://spinitron.com/WCBN/pl/20092843/Live-from-the-Dungeon"
    const lastDescription = "For the final episode of Live from the Dungeon, we learn what it truly means to be straight out the dungeons of rap. Enjoy. "
    const spins = [
        { num: "1", artist: "Nas", track: "N.Y. State of Mind", album: "Illmatic", label: "Legacy Recordings", year: "1994", cover: require("../../assets/Spins/spin1.jpg") },
        { num: "2", artist: "Lords of the Underground", track: "What I'm After", album: "Keepers of the Funk", label: "Pendulum", year: "1995", cover: require("../../assets/Spins/spin2.jpg") },
        { num: "3", artist: "Organized Konfusion", track: "Stress", album: "Stress: The Extinction Agenda", label: "OK Music", year: "1994", cover: require("../../assets/Spins/spin3.jpg") },
        { num: "4", artist: "Smif-N-Wessun", track: "Bucktown", album: "Dah Shinin'", label: "Nervous Records", year: "1995", cover: require("../../assets/Spins/spin4.jpg") },
        { num: "5", artist: "J-Live", track: "Them That's Not", album: "The Best Part", label: "Mortier Music LLC", year: "2001", cover: require("../../assets/Spins/spin5.jpg") },
        { num: "6", artist: "Da Youngsta's", track: "I'll Make U Famous", album: "I'll Make U Famous", label: "90's Tapes / HHV", year: "1995", cover: require("../../assets/Spins/spin6.jpg") },
        { num: "7", artist: "Ill Al Skratch & Brian McKnight", track: "I'll Take Her (feat. Brian McKnight)", album: "Creep Wit' Me", label: "Universal Music Canada", year: "1994", cover: require("../../assets/Spins/spin7.jpg") },
        { num: "8", artist: "M.O.P.", track: "To the Death", album: "To the Death", label: "On The Low", year: "1994", cover: require("../../assets/Spins/spin8.jpg") },
        { num: "9", artist: "De La Soul", track: "Stakes Is High", album: "Stakes Is High", label: "Tommy Boy Records", year: "1996", cover: require("../../assets/Spins/spin9.jpg") },
        { num: "10", artist: "Black Moon", track: "Buck Em Down", album: "Enta Da Stage", label: "Nervous Records", year: "1993", cover: require("../../assets/Spins/spin10.jpg") },
        { num: "11", artist: "Eric B. & Rakim", track: "Don't Sweat the Technique", album: "Don't Sweat the Technique", label: "Geffen*", year: "1992", cover: require("../../assets/Spins/spin11.jpg") },
        { num: "12", artist: "Mobb Deep", track: "Temperature's Rising (feat. Crystal Johnson)", album: "The Infamous", label: "RCA Records Label", year: "1995", cover: require("../../assets/Spins/spin12.jpg") },
        { num: "13", artist: "Common", track: "I Used to Love H.E.R.", album: "Resurrection", label: "Relativity Records", year: "1994", cover: require("../../assets/Spins/spin13.jpg") },
    ];

    return (
        <div className='lastEpisode'>
            <h1>Last Episode<hr className='headerHR'/></h1>
            <div className='recentContent'>
                <h2 className='lastLink'><a href={driveLink}>{epName}: <img src={ download } className='downloadIcon' alt='Download Icon' width='25px'/></a></h2>
                <div className='lastDescription'>{lastDescription}</div>
                <NowPlaying name={epName} linkSrc={audioSrc} />
                <hr/>
                <div className='tracks'>
                    <h2 className='lastLink'><a href={spinLink} target='_blank' rel='noreferrer'>Playlist:  <img src={ external } className='externalIcon' alt='External Icon' width='20px'/></a></h2>
                    {spins.map((trackInfo, index) => (
                        <Spin key={index} {...trackInfo} />
                    ))}
                </div>
            </div>
            <hr/>
        </div>
    );
};

export default MostRecent;